<template>
  <div class="">
    <b-overlay rounded="sm" :show="show">
      <div class="list-view product-checkout mt-0">
        <div class="checkout-items order-last order-lg-first">
          <b-card class="bg-danger" v-if="!task.before_pics">
            <h5 class="text-white">¡ACUÉRDATE DE AÑADIR FOTOS DE INICIO DE SERVICIO!</h5>
          </b-card>

          <b-card>
            <form ref="formWO">
              <input type="hidden" name="id" v-model="task.id">
              <b-row class="mb-1">
                <b-col md="6" class="text-center">
                  <h4>Fecha inicio</h4>
                  <b-form-input type="datetime-local" v-if="!task.agreed_start_date" v-model="task.date_start" name="date_start" disabled></b-form-input>                    
                  <b-form-input type="datetime-local" v-else v-model="task.agreed_start_date" name="agreed_start_date"  disabled></b-form-input>                  
                  </b-col>
                <b-col md="6" class="text-center">
                  <h4>Fecha fin</h4>
                  <b-form-input type="datetime-local" v-if="!task.agreed_start_date" v-model="task.date_end" name="date_end" disabled></b-form-input>
                  <b-form-input type="datetime-local" v-else v-model="task.agreed_end_date" name="agreed_end_date" disabled></b-form-input>
                </b-col>
                <b-col md="12" class="text-center mt-4 mb-4">
                  <h4>Comentarios</h4>
                  <b-form-textarea v-model="task.notes" name="notes" @change="updateForm"></b-form-textarea>
                </b-col>
              </b-row>             
              <b-row>
                <b-col md="6" class="text-center">
                  <h4>Imágenes inicio tarea</h4>
                  <div class="mt-75 ml-75">                
                    <span v-for="file, index in files.before_pics">              
                      <a :href="$http.defaults.baseURL + 'storage/app/private/' + file" target="_blank">
                        <img :src="$http.defaults.baseURL + 'storage/app/private/' + file" width="150">
                      </a>
                      <feather-icon class="ml-1 text-danger" icon="XCircleIcon" size="16" @click="removePic( 'before_pics',file, index)" />
                    </span>                
                      <b-form-file browse-text="Buscar" placeholder="Adjuntar imágenes" v-model="beforePics" class="mb-1"
                        accept=".jpg, .png, .gif" ref="before_pics" multiple> </b-form-file>
                      <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('before_pics')"> Subir</b-button>               
      
                    <!-- <b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="beforePic = null"> Reset </b-button>-->
                  </div>
                </b-col>               

                <b-col md="6" class="text-center">
                  <h4>Imágenes final tarea</h4>
                  <div class="mt-75 ml-75">
                    <span v-for="file, index in files.after_pics">
                      <a :href="$http.defaults.baseURL + '../storage/app/private/' + file" target="_blank">
                        <img :src="$http.defaults.baseURL + '../storage/app/private/' + file" width="150">
                      </a>
                      <feather-icon class="ml-1 text-danger"  icon="XCircleIcon" size="16" @click="removePic('after_pics',file, index)" />
                    </span>
                    <b-form-file browse-text= "Buscar" placeholder="" class="mb-1" v-model="afterPics" accept=".jpg, .png, .gif" ref="after_pics" multiple> </b-form-file>
                    <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('after_pics')"> Subir</b-button>
                     <!--<b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="AfterPic = null"> Reset </b-button> -->
                  </div>
                </b-col>
                <b-col md="6" class="text-center">
                  <h4>Añadir archivos</h4>
                  <div class="mt-75 ml-75">
                    <p v-for="file, index in files.others_files">
                      <a :href="$http.defaults.baseURL + '../storage/app/private/' + file.download_link" target="_blank" download>
                       <span>{{ file.original_name }}</span>                       
                      </a>
                      <feather-icon class="ml-1 text-danger"  icon="XCircleIcon" size="16" @click="removePic('others_files',file, index)" />
                    </p>
                    <b-form-file browse-text= "Buscar" placeholder="" class="mb-1" v-model="others_files" ref="others_files" multiple> </b-form-file>
                    <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('others_files')"> Subir</b-button>
                     <!--<b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="AfterPic = null"> Reset </b-button> -->
                  </div>
                </b-col>
       
              </b-row>
            </form>
          </b-card>
         
        </div>
        <b-card>
          <h4 class="card-title">Datos Cliente
            <b-button variant="success" class="btn-sm float-right" @click="editClient()">Editar</b-button>
          </h4>
          <hr>        
          <h5 class="font-weight-bold">{{ task.client.name }}</h5>
          <p class="mb-0">NIF: {{ task.client.nif }}</p>
          <p class="mb-0">Dirección: {{ task.client.address }}</p>
          <p class="mb-0"> {{ task.client.cp+' - '+task.client.location }}</p>
          <p class="mb-0"> Teléfonos: {{ task.client.phone1 + ' ' + (task.client.phone2 ? ' - '+task.client.phone2 : '') }}</p>
          <p class="mb-0">Email: {{ task.client.email }}</p>
          <p class="mb-0 font-weight-bold">Contacto: {{ task.client.contact }}</p>
        </b-card> 

        <!-- DATOS TAREA -->
        <div class="checkout-options">
     
          <b-card class="position-relative client-card">
            <h5 :class="'bg-' + task.task_category.color + ' card-title mb-25'"> {{ task.task_category.name + ' - '+task.description }}
              <b-badge class="ml-3 bg-success"> {{ status[task.status] ? status[task.status].text : '' }}</b-badge>
              <b-badge v-if="task.urgency == 1" class="ml-3 bg-primary">Urgente</b-badge>
            </h5>
            <hr>
           
            <!-- <b-button variant="success" class="btn-sm float-right" @click="reschedule()">Reprogramar</b-button> -->
            <template v-if="task.client.address">
              <h5> <strong>Dirección Actuación</strong></h5>
             
              <p class="mb-0"> {{ task.client.address + ' - CP: ' + task.client.cp }}</p>
              <p class="mb-0"> {{ 'CP: ' + task.client.cp }}</p>
              <p class="mb-1"> {{ 'Población: ' + task.client.location }}</p>
              <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updateMeeting" block> Reprogramar cita</b-button>
              <b-button variant="danger" class="mb-2" @click="closeTask" block>Cerrar tarea</b-button>  
              <hr class="mt-4">
            </template>
            <template v-if="task.client.phone1 || task.client.phone2">
              <h5><strong>Teléfonos</strong></h5>
              <span v-if="task.client.phone1"><strong>Principal: </strong>{{ task.client.phone1 }}<br></span>
              <span v-if="task.phone2"><span v-if="task.phone2"><strong>Otros:</strong><br></span>
                <p v-html="task.phone2"></p> {{ task.phone2 }}
              </span>
              <hr>
              <h5 v-if="task.the_budget"><strong>Presupuesto vinculado Nº {{task.the_budget.doc_num}}</strong></h5>
              <p v-if="task.the_budget"><strong>Importe pendiente de pago:</strong><span> {{budget_amount_pending}}€</span></p>
              <b-button v-if="task.budget_id" variant="success" class="float-left" @click="printBudget()" block>Visualizar</b-button>             
              <h6 v-else>No hay presupuesto vinculado</h6>   
              <b-button class="mt-4" v-if="task.budget_id" @click="insertReportFromBudget" variant="primary" block>Crear parte sobre presupuesto vinculado</b-button>
              <b-button class="" v-if="task.budget_id" @click="openChargeModal" variant="info" block>Añadir cobro a presupuesto vinculado</b-button>
              <hr class="mb-2 mt-4" v-if="task.invoice">
              <h5 v-if="task.invoice">Factura vinculada Nº <strong>{{task.invoice ? task.invoice.doc_num : ''}}</strong></h5>  
              <b-button v-if="task.invoice_id" variant="success" class="float-left" @click="printInvoice()" block>Visualizar</b-button>          
              <hr class="mb-2 mt-4">
                
            </template>
           
          </b-card>       
          
          <b-card  title="Partes de trabajo">
            <b-list-group >
              <b-link :href="'/budget/' + item.id " v-for="item,index in reports" :key="index">
                <b-list-group-item>  
                   <b-badge pill :variant="status2[item.status].class" class="mr-1" v-if="!show">{{status2[item.status].text}}</b-badge> {{item.doc_num}} 
                </b-list-group-item>                                
              </b-link>
            </b-list-group>
            <b-button class="mt-1" variant="primary" block @click="verificarCliente">Crear parte de trabajo</b-button>
          </b-card>         

           <b-card  title="Presupuestos">
            <b-list-group >      
              <b-link :href=" item.status == 0 && !is_budgeteable ? '#' : '/budget/' + item.id " v-for="item,index in task.only_budgets" :key="index">
                <b-list-group-item>  
                   <b-badge pill :variant="status2[item.status].class" class="mr-1" v-if="!show">{{status2[item.status].text}}</b-badge> {{item.doc_num}} 
                </b-list-group-item>                                
              </b-link>
            </b-list-group>
            <b-button v-if="this.user.employee && this.user.employee.type == 0" class="mt-1" :to="'/create-budget/' + task.id" variant="primary" block>Crear presupuesto</b-button>
          </b-card>
           

        </div>
      </div>
    </b-overlay>

    <!-- The modal for Expenses -->

    <!-- The modal for edit Client -->

    <b-modal id="edit-client-modal" title="Editar datos cliente">     
      <label class="mt-1">Nombre</label><b-form-input type="text" name="name" v-model="client.name"></b-form-input>
      <label class="mt-1">NIF</label><b-form-input type="text" name="nif" v-model="client.nif"></b-form-input>
      <label class="mt-1">Dirección</label><b-form-input type="text" v-model="client.address">{{ client.address
        }}</b-form-input>
      <label class="mt-1">Código postal</label><b-form-input type="text" v-model="client.cp"></b-form-input>
      <label class="mt-1">Población</label><b-form-input type="text" v-model="client.location">{{ client.location
        }}</b-form-input>
      <label for="aux-phone" class="mt-1">Teléfono 1</label><b-form-input id="aux-phone" type="text"
        v-model="client.phone1" :state="is_valid_phone_aux">{{ client.phone1 }}</b-form-input>
      <div v-if="is_valid_phone_aux == false" id="auxphone-feedback" class="feedback-form">
        El teléfono no debe contener espacios ni carácteres especiales (puede contener un + al principio)
      </div>
      <label for="phone2" class="mt-1">Teléfono 2</label><b-form-input id="phone2" type="text" v-model="client.phone2"
        :state="is_valid_phone">{{ client.phone2 }}</b-form-input>
      <div v-if="is_valid_phone == false" id="phone-feedback" class="feedback-form">
        El teléfono no debe contener espacios ni carácteres especiales (puede contener un + al principio)
      </div>
      <label class="mt-1">Email</label><b-form-input type="text" v-model="client.email">{{ client.email
        }}</b-form-input>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="4">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="saveClient">Aceptar</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal id="reschedule-modal" title="Reprogramar cita">
      <p>Selecciona nueva fecha y hora de inicio y fin de servicio</p>
      <form ref="formMeeting">
        <label class="mt-1">Fecha inicio reprogramada</label>
        <input type="hidden" name="task_id" :value="task.id">
        <b-form-input type="datetime-local" name="agreed_start_date" class="mb-2" v-model="agreed_start_date"></b-form-input>
        <label class="mt-1">Fecha fin reprogramada</label>
        <b-form-input type="datetime-local" name="agreed_end_date" v-model="agreed_end_date"></b-form-input>
      </form>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="4">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="aceptNewMeeting">Aceptar</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="charge-modal" class="w-100 h-auto" title="Añadir cobro a presupuesto vinculado"> 
      <form ref="formReport">
        <input type="hidden" name="budget_id" v-model="budget_id">
        <!-- form -->
        <b-row>        
          <b-col sm="4">
            <label for="">Fecha</label>
              <b-form-input type="date" v-model="receipt_date" name="date"></b-form-input>       
          
          </b-col>
          <b-col sm="4">           
            <label for="">Importe</label>            
              <b-form-input type="number" v-model="amount" name="amount"></b-form-input>
           
          </b-col>
          
          <b-col sm="4">           
            <label for="">Comentario</label>
              <b-form-textarea name="description" v-model="description" />    
    
          </b-col>
          <b-col sm="4">           
            <label for="">Método de pago</label>
              <v-select
                :options="payment_methods"                
                placeholder="Seleccione método"
                label="title"
                :getOptionKey="getOptionKey"
                v-model="method_id"
                name="payment_method_id"
              />           
        
          </b-col>
        </b-row> 
        
      </form>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="4">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="saveForm">Añadir cobro</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>


  </div>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';
import vSelect from 'vue-select'
import { mapState } from 'vuex';
import { BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BListGroup, BCardText, BFormFile, BListGroupItem, BBadge, BOverlay, BCardBody, BLink, BButton, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable } from "bootstrap-vue";
export default {
  components: { vSelect, BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BCardText, BListGroup, BFormFile, BListGroupItem, BBadge, BCardBody, BLink, BButton, BOverlay, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable },
  data() {

    return {
      show: true, 
      client: {},      
      beforePics: [],
      afterPics: [],
      others_files: [],
      files: { before_pics: [], after_pics: [], others_files: []},  
      task_id: this.$route.params.task,
      task: { client: {}, task_category: {} },
      optionsCp: [],
      isDateFieldDisabled: true,
      budget_amount_pending : 0,
      optionsCpSelected: null,
      agreed_start_date : null,
      agreed_end_date : null,
      fields: ['first_name', 'last_name', 'age'],
      reports : null,
      receipt_date : new Date().toISOString().slice(0,10),
      internal_note: null,  
      description: null,
      payment_methods: [],       
      method_id: null, 
      budget_id: null,
      amount: null,    

      status: [
        { text: 'Pendiente de programar', id: 0 },
        { text: 'Programada', id: 1 },
        { text: 'Realizada', id: 2 },
        { text: 'Nueva', id: 3 },
        { text: 'En proceso', id: 4 },
      ],
      status2: [  
        { id: 0 ,text: '', class: ''},
        { id: 1 ,text: 'emitido', class: 'secondary'},
        { id: 2 ,text: '', class: ''},
        { id: 3, text: 'rechazado', class: 'danger'},
        { id: 4, text: 'aceptado', class: 'success'},
        { id: 5,text: 'facturado', class: 'warning'},
      ],
       
    }
  },
  created() {
    this.$http.get("/api/getMethods").then((response) => {
        this.payment_methods = response.data;       
    })
    
    this.$http.get("/api/getTask?id=" + this.task_id).then((response) => {
      this.task = response.data;
      this.budget_id = this.task.budget_id

      if (this.task.budget_id) {
        this.$http.get("/api/getAmountPending?id=" + this.budget_id).then((response) => {    
          this.amount = response.data
          this.budget_amount_pending = response.data
        });
      }          
 
      this.files.before_pics = this.task.before_pics ? JSON.parse(this.task.before_pics) : null;
      this.files.after_pics = this.task.after_pics ? JSON.parse(this.task.after_pics) : null;
      this.files.others_files = this.task.files ? JSON.parse(this.task.files) : null;
      if (this.task.closing_date) {
        this.task.closing_date = moment(this.task.closing_date).format('YYYY-MM-DDTHH:mm');
      }
      this.task.date_start = this.task.date_start ? moment(this.task.date_start).format('YYYY-MM-DDTHH:mm') : '';
      this.task.date_end = this.task.date_end ? moment(this.task.date_end).format('YYYY-MM-DDTHH:mm') : '';
      this.task.agreed_start_date = this.task.agreed_start_date ? moment(this.task.agreed_start_date).format('YYYY-MM-DDTHH:mm') : '';
      this.task.agreed_end_date = this.task.agreed_end_date ? moment(this.task.agreed_end_date).format('YYYY-MM-DDTHH:mm') : '';

      this.show = false;

      if (this.task.status == 3) {
      // si ha entrado en una tarea nueva, cambiar estado a en proceso.
        this.$http.post("/api/updateStatus", {id : this.task.id}).then((response) => {
          this.task.status = response.data;
        }).catch(response => { window.console.log(response) });   
      }   
       // comprobar si tarea ya ha sido abierta, si no enviar correo
      if (this.task.open_task_by_tech == 0) {
        this.$http.get("/api/openTaskNotification?id="+this.task.id).then((response) => {
            this.task.open_task_by_tech = response.data;       
        })        
      }

    });

    this.client = this.task.client;

    // coger todos los partes que tenga la tarea
    this.$http.get("/api/getReports?id=" + this.task_id).then((response) => {    
      this.reports = response.data
    });

  },

  methods: {
    getOptionKey(option) {
        return option ? option.id : null;
      },
    openChargeModal(){
      this.$bvModal.show('charge-modal')
    },
    saveForm : async function (e) {
      this.show = true;  
      var formData = new FormData(this.$refs.formReport);   
      if (!this.method_id) {
        this.$bvToast.toast('ERROR : Método de pago es obligatorio', { variant: 'danger', noCloseButton: true });
        return
      }   
      formData.set('method_id', this.method_id ? this.method_id.id : null);
      
      await this.$http({
      method: "post",
      url: "/api/insertReceipt",
      data: formData,
        }).then(response => { 
          this.$http.get("/api/getAmountPending?id=" + this.budget_id).then((response) => {    
            this.amount = response.data
            this.budget_amount_pending = response.data
          });

          this.show = false;  
          this.$bvModal.hide('charge-modal')
          this.$bvToast.toast('Cobro añadido con éxito', { variant: 'success', noCloseButton: true });         
        }).catch(response => { window.console.log(response) });
     
    },
    verificarCliente(){
  
      if (!this.task.client.address || !this.task.client.cp || !this.task.client.location || !this.task.client.phone1 || !this.task.client.email || !this.task.client.nif || !this.task.client.name) {     
        this.$bvModal.msgBoxConfirm('Faltan datos importantes del cliente (alguno de estos: nombre, dni, dirección, CP, población, tel, email), estás seguro de crear parte de trabajo?',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
        .then(value => {
          if(value) {    
            this.$router.push("/create-report/"+this.task.id); 
          }
        })
      }else{
        this.$router.push("/create-report/"+this.task.id); 
      }
    },
    insertReportFromBudget(){   
      
      this.$bvModal.msgBoxConfirm('¿Estás seguro de crear parte desde presupuesto vinculado?',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
        .then(value => {
          if(value) {      
            this.$http.post("/api/insertReportFromBudget", {'id':this.task.budget_id, 'task': this.task.id}).then((response) => {
              console.log(response.data)
              if (response.data) {
                let id = response.data
                this.$router.push("/budget/"+id);          
              }
            })
          }});
    },
    aceptNewMeeting: async function (e) {
      let that = this
      var formData = new FormData(this.$refs.formMeeting);
      this.$http({
        method: "post",
        url: "/api/updateMeeting",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then((response) => {
        that.task.agreed_start_date = response.data.agreed_start_date;
        that.task.agreed_end_date = response.data.agreed_end_date;
        this.$bvModal.hide('reschedule-modal')
        this.$bvToast.toast('Cita reprogramada con con éxito', { variant: 'success', noCloseButton: true });
      });
    },
       
    closeTask(){  
     
        this.$bvModal.msgBoxConfirm('¿Estás seguro de finalizar esta tarea? Acuérdate de añadir fotos de inicio/fin de servicio',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
        .then(value => {
          if(value) {      
            let params = { task: this.task.id }      
            this.$http.post("/api/closeTask",params).then((response) => {
              console.log(response.data)
                this.task.status = response.data
                this.$bvToast.toast('Tarea finalizada con éxito', { variant: 'success', noCloseButton: true });
              });  
            }
          }) 
      
    },
    toggleDateField() {
      this.isDateFieldDisabled = !this.isDateFieldDisabled;
    },
    formatDate(date){
      date = moment(date).format('DD/MM/YYYY');
      return date;
    },
    printInvoice() {
      this.$http({
        url: "/api/printInvoice/" + this.task.invoice_id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if (response.status == 200) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'factura.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
    },

    printBudget() {
      this.$http({
        url: "/api/printBudget/" + this.task.budget_id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(response.status == 200){
          var fileURL = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'presupuesto.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
    },
   
    updateMeeting(){
        this.$bvModal.show('reschedule-modal')        
    },
  
    formatTime(date) {
      let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
      let format_time = new Date(date).toLocaleTimeString('es-es', { hour: '2-digit', minute: '2-digit' });
      return format_date + ' - ' + format_time
    },
    updateForm() {
      this.show = true;
      var formData = new FormData(this.$refs.formWO);
      this.$http({
        method: "post",
        url: "/api/updateTask",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => {
        this.show = false;
        this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success', noCloseButton: true });
      })
    },

    // FOTOS 
    updatePictures(source) {    
      const files = source == 'before_pics' ? this.beforePics : (source == 'after_pics' ? this.afterPics : this.others_files);
     
      const formData = new FormData();
      formData.append('task_id', this.task.id);
      formData.append('source', source);

      for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]); // Aquí agregamos el archivo real, no solo su nombre
      }   

      const headers = { 'Content-Type': 'multipart/form-data' };

      this.$http.post("/api/addPic", formData, { headers })
        .then((response) => {       
          if (response.data) {
            if (source === 'before_pics') {
              this.files.before_pics = response.data;
            } else if(source === 'after_pics') {
              this.files.after_pics = response.data;
            }else{
              this.files.others_files = response.data;
            }
            this.$bvToast.toast('Imagen/archivo subidos con éxito', { variant: 'success', noCloseButton: true });
            
          } else {
            this.$bvToast.toast('No has seleccionado fotos/archivos para subir', { variant: 'danger', noCloseButton: true });

          }  
        })
        .catch(error => {
          console.error(error);
          this.$bvToast.toast('Error al subir las imágenes.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
        });
      
    },
    removePic(source,file,index) {
     let source2 = source == 'others_files' ? 'files' : source;    
      this.$bvModal.msgBoxConfirm('¿Desea eliminar el archivo?',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {      
          let params = { slug:'personal-tasks', filename: (source == 'others_files' ? file.original_name : file), id: this.task.id, field: source2, multi: true }      
          this.$http.post("/api/removePic",params).then((response) => {
              this.files[source].splice(index,1)
              this.$bvToast.toast('Imagen/archivo eliminada/o con éxito', { variant: 'success', noCloseButton: true });
            });  
          }
        })   
    },

    editClient() {  
      this.client = this.task.client;
      this.$bvModal.show('edit-client-modal')
    },

    saveClient: async function (e) {

      let that = this
      this.$http.post("/api/updateClient", this.client).then((response) => {
        if (response.data) {
          that.task.client = that.client = response.data
          that.$forceUpdate();
        } else {
          alert('Los datos no han podido ser almacenados')
        }
        this.$bvModal.hide('edit-client-modal')
      });    

    }, 

  },
  computed: {
    is_valid_phone: function () {
      const regex = new RegExp('^[+]?[0-9]*$');
      if (!this.client.phone || this.client.phone.length == 0) return null;
      return regex.test(this.client.phone || "");
    },
    is_valid_phone_aux: function () {
      const regex = new RegExp('^[+]?[0-9]*$');
      if (!this.client.phone_aux || this.client.phone_aux.length == 0) return null;
      return regex.test(this.client.phone_aux || "");
    },
    ...mapState({
      user: state => state.auth.user,   
    })
  },

};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';


</style>
